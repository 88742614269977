import React, { Component } from "react";
import { motion } from "framer-motion";

function Footer() {
  const height = {
    height: "1px",
  };

  return (
    <footer className="px-12 lg:px-24 flex flex-col gap-4 py-12 text-white bg-zinc-950">
      <span className="bg-white w-full" style={height}></span>
      <p>by Filip Kania</p>
      <link rel="stylesheet" href="https://use.typekit.net/cdx7zdz.css"></link>
    </footer>
  );
}

export default Footer;
