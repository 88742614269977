import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
function Contact() {
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: "-50px 0px",
  });

  const [ref2, inView2] = useInView({
    triggerOnce: false,
  });
  return (
    <section
      id="contact"
      className="flex flex-col w-full lg:flex-row lg:items-center justify-between px-12 lg:px-24"
    >
      <div className="w-full">
        <motion.div
          ref={ref}
          className={`w-full box ${inView ? "visible" : ""}`}
          initial={{ opacity: 0, scale: 0.5 }}
          animate={inView ? { opacity: 1, scale: 1 } : {}}
          transition={{
            duration: 0.8,
            delay: 0.5,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div className="flex flex-col lg:flex-row items-center justify-between w-full gap-6 lg:gap-4 mt-12 lg:mt-2">
            <h2 className="w-full text-2xl lg:text-4xl text-white leading-none">
              Let's work togather!
            </h2>
            <a
              className="text-white text-2xl lg:text-4xl w-full lg:text-right"
              href="mailto:hello@filipkania.com"
            >
              hello@filipkania.com
            </a>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default Contact;
