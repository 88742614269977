import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { motion } from "framer-motion";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
} from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import ParticlesBg from "./particles";

function Hero() {
  const text = "Filip Kania".split(" ");
  const textTwo =
    "I am a graduate of the Cyber Security major at Wrocław University of Technology. After one year of studies I started working on my own brand iFil creating websites, online shops, booking systems and graphic design. As iFil Web Design grew, I made a decision to expand my offer to include the development of web applications. In parallel with the development of my own iFil brand, I continue to learn as a full-stack developer and create applications, mainly in Python Django technology.".split(
      " "
    );

  const [init, setInit] = useState(false);

  return (
    <section className="h-screen flex flex-col lg:flex-row items-center relative">
      <div className="py-12 lg:py-24 pl-12 lg:pl-24 z-40 w-full h-full text-white text-9xl flex flex-col items-start justify-between">
        <div className="lg:scale-110">
          {text.map((el, i) => (
            <motion.h1
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 2,
                delay: i / 3,
              }}
              key={i}
            >
              {el}{" "}
            </motion.h1>
          ))}
          <div className="flex flex-row items-center justify-start gap-4 mt-6">
            <a href="https://www.linkedin.com/in/filip-kania-00/">
              <svg
                width="35"
                height="35"
                fill="#ffffff"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-143 145 512 512"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path d="M113,145c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256S254.4,145,113,145z M41.4,508.1H-8.5V348.4h49.9 V508.1z M15.1,328.4h-0.4c-18.1,0-29.8-12.2-29.8-27.7c0-15.8,12.1-27.7,30.5-27.7c18.4,0,29.7,11.9,30.1,27.7 C45.6,316.1,33.9,328.4,15.1,328.4z M241,508.1h-56.6v-82.6c0-21.6-8.8-36.4-28.3-36.4c-14.9,0-23.2,10-27,19.6 c-1.4,3.4-1.2,8.2-1.2,13.1v86.3H71.8c0,0,0.7-146.4,0-159.7h56.1v25.1c3.3-11,21.2-26.6,49.8-26.6c35.5,0,63.3,23,63.3,72.4V508.1z "></path>{" "}
                </g>
              </svg>
            </a>
            <a href="https://github.com/FilipK00">
              <svg
                width="35"
                height="35"
                viewBox="0 0 20 20"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <title>github [#142]</title> <desc>Created with Sketch.</desc>{" "}
                  <defs> </defs>{" "}
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    {" "}
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-140.000000, -7559.000000)"
                      fill="#ffffff"
                    >
                      {" "}
                      <g
                        id="icons"
                        transform="translate(56.000000, 160.000000)"
                      >
                        {" "}
                        <path
                          d="M94,7399 C99.523,7399 104,7403.59 104,7409.253 C104,7413.782 101.138,7417.624 97.167,7418.981 C96.66,7419.082 96.48,7418.762 96.48,7418.489 C96.48,7418.151 96.492,7417.047 96.492,7415.675 C96.492,7414.719 96.172,7414.095 95.813,7413.777 C98.04,7413.523 100.38,7412.656 100.38,7408.718 C100.38,7407.598 99.992,7406.684 99.35,7405.966 C99.454,7405.707 99.797,7404.664 99.252,7403.252 C99.252,7403.252 98.414,7402.977 96.505,7404.303 C95.706,7404.076 94.85,7403.962 94,7403.958 C93.15,7403.962 92.295,7404.076 91.497,7404.303 C89.586,7402.977 88.746,7403.252 88.746,7403.252 C88.203,7404.664 88.546,7405.707 88.649,7405.966 C88.01,7406.684 87.619,7407.598 87.619,7408.718 C87.619,7412.646 89.954,7413.526 92.175,7413.785 C91.889,7414.041 91.63,7414.493 91.54,7415.156 C90.97,7415.418 89.522,7415.871 88.63,7414.304 C88.63,7414.304 88.101,7413.319 87.097,7413.247 C87.097,7413.247 86.122,7413.234 87.029,7413.87 C87.029,7413.87 87.684,7414.185 88.139,7415.37 C88.139,7415.37 88.726,7417.2 91.508,7416.58 C91.513,7417.437 91.522,7418.245 91.522,7418.489 C91.522,7418.76 91.338,7419.077 90.839,7418.982 C86.865,7417.627 84,7413.783 84,7409.253 C84,7403.59 88.478,7399 94,7399"
                          id="github-[#142]"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>{" "}
                </g>
              </svg>
            </a>
          </div>
        </div>
        <svg
          width="35"
          height="35"
          className="hidden lg:flex animate-bounce"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g fill="none" fill-rule="evenodd">
              {" "}
              <path d="m0 0h32v32h-32z"></path>{" "}
              <path
                d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm0 2c-7.7319865 0-14 6.2680135-14 14s6.2680135 14 14 14 14-6.2680135 14-14-6.2680135-14-14-14zm-4.9728932 11.4579733 4.9708932 4.971228 4.9717475-4.971228c.3905243-.3905243 1.0236892-.3905243 1.4142135 0s.3905243 1.0236893 0 1.4142136l-5.6568542 5.6568542c-.180242.180242-.4121708.2772954-.6480754.2911602h-.1612085c-.2359047-.0138648-.4678335-.1109182-.6480754-.2911602l-5.65685428-5.6568542c-.39052429-.3905243-.39052429-1.0236893 0-1.4142136.39052428-.3905243 1.02368928-.3905243 1.41421358 0z"
                fill="#ffffff"
                fill-rule="nonzero"
              ></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
      <div className="pl-12 lg:pl-0 pr-12 lg:pr-24 py-12 lg:py-24 z-40 w-full h-full flex flex-col lg:items-end lg:justify-end text-white text-lg">
        <div>
          {textTwo.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
                delay: i / 20,
              }}
              key={i}
            >
              {el}{" "}
            </motion.span>
          ))}
        </div>
      </div>
      <video
        loop={true}
        muted={true}
        autoPlay={true}
        className="hidden absolute lg:flex w-full"
        src="https://ifil-group-spaces.fra1.cdn.digitaloceanspaces.com/Projects/filipkania-com/portfolio/app-video.mp4"
      ></video>
      <div className="w-full h-full backdrop-blur-sm z-20 hidden lg:flex absolute appsGradient">
        {" "}
        <ParticlesBg />
      </div>
      <div className="w-full absolute heroGradient h-full bottom-0 z-20"></div>
    </section>
  );
}

export default Hero;
